export const baseUrl = getUrlApi()
export const baseUrlFront = getUrlApp()

function getUrlApi(){
    let location = window.location.hostname
    return 'http://44.215.178.78:801'
    /*if(location == 'sig-mind.com'){
       return 'https://backend.sig-mind.com'
    }else if(location == 'localhost' ){
       return 'https://localhost:5001'
    }
    else{
         return 'http://serv2.fmxsolucoes.com.br:33920'
    }*/
}

function  getUrlApp(){
   let location = window.location.hostname
   if (!location.match(/http:/))
      return "http://"+location
   else
      return location

   /*
   if(location == 'sig-mind.com'){
       return 'http://sig-mind.com'
   }else if(location == 'localhost'){
       return 'https://localhost:5001'
   }
   else{
      return 'http://serv2.fmxsolucoes.com.br:33920'
   }
   */
}

// --- BaseURL para endpoint na api servidor Desenvolvimento ---
// export const baseUrl = 'http://serv2.fmxsolucoes.com.br:33920'
// export const baseUrlFront = 'http://serv2.fmxsolucoes.com.br:33919'

// --- BaseURL para endpoint na api Produção ---
// export const baseUrl = 'https://backend.sig-mind.com'
// export const baseUrlFront = 'http://sig-mind.com'