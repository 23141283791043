export default [
  {
    path: '/localTrabalho',
    name: 'consultar-trabalho',
    component: () => import('@/views/localTrabalho/View.vue'),
    meta: {
      pageTitle: 'Local de Trabalho',
      breadcrumb: [
        {
          text: 'Local de Trabalho',
          active: true,
        },
      ],
    },
  },
  {
    path: '/localTrabalho/cadastrar',
    name: 'cadastrar-trabalho',
    component: () => import('@/views/localTrabalho/LocalTrabalho.vue'),
    meta: {
      pageTitle: 'Cadastro de local',
      breadcrumb: [
        {
          text: 'Local de Trabalho',
          to: { name: 'consultar-trabalho' },
        },
        {
          text: 'Cadastro de local',
          active: true,
        },
      ],
    },
  },
  {
    path: '/localTrabalho/editar/:idDoLocal',
    name: 'editar-trabalho',
    component: () => import('@/views/localTrabalho/LocalTrabalho.vue'),
    meta: {
      pageTitle: 'Edição de local',
      breadcrumb: [
        {
          text: 'Local de Trabalho',
          to: { name: 'consultar-trabalho' },
        },
        {
          text: 'Edição de local',
          active: true,
        },
      ],
    },
  },
  {
    path: '/localTrabalho/visualizar/:idDoLocal',
    name: 'visualizar-trabalho',
    component: () => import('@/views/localTrabalho/LocalTrabalho.vue'),
    meta: {
      pageTitle: 'Visualizar local',
      breadcrumb: [
        {
          text: 'Local de Trabalho',
          to: { name: 'consultar-trabalho' },
        },
        {
          text: 'Visualizar local',
          active: true,
        },
      ],
    },
  },
]
