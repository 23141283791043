export default [
  // {
  //   path: '/cargos',
  //   name: 'roles',
  //   component: () => import('@/views/forms/Roles/Roles.vue'),
  //   meta: {
  //     pageTitle: 'Cargos',
  //     breadcrumb: [
  //       {
  //         text: 'Cargos',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/cargos/cadastro',
    name: 'role-add',
    component: () => import('@/views/forms/Roles/Register.vue'),
    meta: {
      pageTitle: 'Cargos',
      breadcrumb: [
        {
          text: 'Cargos',
          to: { name: 'role-view' },
        },
        {
          text: 'Cadastro de cargo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cargos',
    name: 'role-view',
    component: () => import('@/views/forms/Roles/View.vue'),
    meta: {
      pageTitle: 'Cargos',
      breadcrumb: [
        {
          text: 'Cargos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/detalhes-cargo/:id',
    name: 'detail-roles',
    component: () => import('@/views/forms/Roles/Register.vue'),
    meta: {
      pageTitle: 'Detalhes do Cargo',
      breadcrumb: [
        {
          text: 'Cargos',
          to: { name: 'role-view' },
        },
        {
          text: 'Detalhes do Cargo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/editar-cargo/:id',
    name: 'role-edit',
    component: () => import('@/views/forms/Roles/Register.vue'),
    meta: {
      pageTitle: 'Editar Cargo',
      breadcrumb: [
        {
          text: 'Cargos',
          to: { name: 'role-view' },
        },
        {
          text: 'Editar Cargo',
          active: true,
        },
      ],
    },
  },
]
