export default [
  {
    path: '/consultar/testes',
    name: 'consultar-testes',
    component: () => import('@/views/consultarTestes/ConsultarTestes.vue'),
    meta: {
      pageTitle: 'Testes',
      breadcrumb: [
        {
          text: 'Consultar',
          active: false,
        },
      ],
    },
  },
  {
    path: '/consultar/resultados/:id/:tipoJogo',
    name: 'consultar-resultados',
    component: () => import('@/views/consultarTestes/ResultadoTestes.vue'),
    meta: {
      pageTitle: 'Resultados',
      breadcrumb: [
        {
            text: 'Consultar',
            to: { name: 'consultar-testes' },
        },
                {
          text: 'Análise detalhada',
          active: true,
        },
      ]
    }
  },

]
