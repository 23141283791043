/* eslint-disable semi */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  tokenExpirou,
  refreshExpirou,
  deslogarUsuario,
  renovaToken,
} from '@/auth/utils'



function redirecionaParaLoginSeTokenExpirou() {
  // console.log('Chegou no início')
  if (getUserData() !== null) {
    // console.log('Chegou no meio')
    // Verifica se o refresh expirou e se o token expirou
    // Desloga usuário caso sim
    // console.log(
    //   `Verifica token: ${tokenExpirou()} e refresh ${refreshExpirou()}`
    // )
    if (tokenExpirou() && refreshExpirou()) {
      // console.log('Chegou no executar')
      deslogarUsuario()
    }
    if (tokenExpirou()) {
        renovaToken()
    }

    // console.log('Continuar')
  }
}

// Os arquivos com as rotas ficarão dispostos no diretório routes
import _main from './routes/_main'
import authentication from './routes/authentication'
import clientes from './routes/clientes'
import usuarios from './routes/usuarios'
import ajuda from './routes/ajuda'
import localTrabalho from './routes/localTrabalho'
import cargos from './routes/cargos'
import teste from './routes/teste'
import configuracoes from './routes/configuracoes'
import consultarTestes from './routes/consultarTestes'
import useJwt from '@/@core/auth/jwt/useJwt'

// Chamou alguma rota vai passar por -->
// [Rotas]
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ..._main,
    ...authentication,
    ...clientes,
    ...usuarios,
    ...ajuda,
    ...localTrabalho,
    ...cargos,
    ...teste,
    ...configuracoes,
    ...consultarTestes,
  ],
})

// Depois de passar por [Rotas] e identificar as mesmas vai conferir se tem permissão para a rota solicitada

// Ao adicionar uma nova rota é necessário ajustar as permissões em:
// src\libs\acl\routeProtection.js
// (permissões é quem vai poder ver: master? administrador? gerente?)

// E ajustar o menu em:
// src\store\user\index.js

router.beforeEach((to, _, next) => {
  // Gera os logs para teste
  // console.log('to: ', to)
  // console.log('_: ', _)
  // console.log('next: ', next)
  // console.log(
  //   'Home do usuário logado: ',
  //   getHomeRouteForLoggedInUser(getUserData().idDoPerfil)
  // )

  // console.log('isUserLoggedIn: ', isUserLoggedIn)

  // Verifica se o token expirou e redireciona para o login
  if (to.name !== 'login') {
    // console.log('Entrou de novo')
    redirecionaParaLoginSeTokenExpirou()

  }
  try {
    // Se chamou o endereço http://localhost:8080/ vai identificar o home do usuário logado e chamar a rota certa
    if (to.name === 'home-page') {
      return next({
        name: getHomeRouteForLoggedInUser(getUserData().idDoPerfil),
      })
    }
    // Entra no if se o usuário não pode navegar na rota
    if (!canNavigate(to.name, getUserData().idDoPerfil)) {
      // Redireciona para o login se o motivo for não ter iniciado a sessão
      if (!isUserLoggedIn()) {
        // console.log('Caiu na opção não logado')
        return next({ name: 'login' })
      }
      // Se estiver logado => não possui autorização, joga ele na home
      return next({ name: 'home-page' })
    }

    // Se chegou aqui libera acessar a rota solicitada
    return next()
  } catch (error) {
    // console.log('Caiu em erro: ', error)
    return next({ name: 'login' })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
