export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  //   path: '/cadastro',
  //   name: 'register',
  //   component: () => import('@/views/authentication/Register.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/esqueci-minha-senha',
    name: 'forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/criar-nova-senha',
    name: 'new-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
]
