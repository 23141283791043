// import ability from './ability'
// export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

// Define por tipo de usuário qual rota ele pode navegar
export function canNavigate(to, role) {
  const routes = [
    {
      // Rotas genéricas (usuários não logados também podem acessar)
      role: 0,
      routes: ['login', 'forgot-password', 'error-404'],
    },
    {
      // Master
      role: 1,
      routes: [
        'home-page',
        'client-page',
        'user-page',
        'user-search',
        'user-register',
        'user-edit',
        'user-import',
        'roles',
        'role-add',
        'role-view',
        'role-edit',
        'detail-roles',
        'client-register',
        'client-edit',
        'client-detail',
        'user-profile',
        'new-password',
        'error-404',
        'configura-concentracao',
        'configura-memoria',
        'consultar-testes',
        'consultar-trabalho',
        'cadastrar-trabalho',
        'editar-trabalho',
        'visualizar-trabalho',
        'consultar-resultados',
      ],
    },
    {
      // Administrador
      role: 2,
      routes: [
        'home-page',
        'user-help',
        'user-page',
        'user-search',
        'user-register',
        'user-edit',
        'user-import',
        'roles',
        'role-add',
        'role-view',
        'detail-roles',
        'role-edit',
        'user-profile',
        'new-password',
        'error-404',
        'consultar-testes',
        'consultar-trabalho',
        'cadastrar-trabalho',
        'editar-trabalho',
        'visualizar-trabalho',
        'test',
        'preconcentracao',
        'concentracao',
        'memoria',
        'prememoria',
        'consultar-resultados',
      ],
    },
    {
      // Gerente
      role: 3,
      routes: [
        'home-page',
        'user-help',
        'user-page',
        'user-search',
        'user-register',
        'user-edit',
        'user-import',
        'roles',
        'role-add',
        'role-view',
        'detail-roles',
        'role-edit',
        'user-profile',
        'new-password',
        'error-404',
        'consultar-testes',
        'test',
        'preconcentracao',
        'concentracao',
        'memoria',
        'prememoria',
        'consultar-resultados',
      ],
    },
    {
      // Gestor
      role: 4,
      routes: [
        'home-page',
        'user-help',
        'user-page',
        'user-search',
        'user-register',
        'user-edit',
        'user-profile',
        'new-password',
        'error-404',
        'consultar-testes',
        'test',
        'preconcentracao',
        'concentracao',
        'memoria',
        'prememoria',
        'consultar-resultados',
      ],
    },
    {
      // Usuário
      role: 5,
      routes: [
        'home-page',
        'user-help',
        'test',
        'user-profile',
        'new-password',
        'error-404',
        'preconcentracao',
        'concentracao',
        'memoria',
        'prememoria',
      ],
    },
  ]

  const genericRoutes = routes.find((o) => o.role === 0)
  const userRoutes = routes.find((o) => o.role === role)
  const userCanNavigate =
    userRoutes.routes.some((item) => item === to) ||
    genericRoutes.routes.some((item) => item === to)

  // console.log('userRoutes: ', userRoutes)
  // console.log('genericRoutes: ', genericRoutes)
  // console.log('userCanNavigate: ', userCanNavigate)

  return userCanNavigate
}

export const _ = undefined
