import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export function getUserData() {
  const userData = JSON.parse(localStorage.getItem('userData'))
  if (userData === null) {
    return {
      idDoPerfil: 0,
      nomeDoUsuario: 'None',
    }
  }
  return userData
}

export function isUserLoggedIn() {
  const isLoggedIn = getUserData()

  // console.log(isLoggedIn)
  if (isLoggedIn.idDoPerfil === 0) {
    // console.log('Está off!')
    return false
  }
  // console.log('Está logado!')
  return true
}

/**
 * Essa função deverá redirecionar o usuário para a rota de acordo com seu tipo
 * @param {...number} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  // console.log('Retorna home do perfil: ', userRole)
  if (!userRole) {
    localStorage.setItem('redirecionado', true)
    return 'login'
  }

  if (userRole === 1) return 'client-page'
  if (userRole === 2 || userRole === 3 || userRole === 4) return 'user-page'
  if (userRole === 5) return 'test'
  return 'login'
}

export function removeAcento(text) {
  text = text.toLowerCase()
  text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  // ESTA COMENTADO MAS FUNCIONA, EXEMPLO MANUAL.
  // text = text.replace(new RegExp('[aáàãäâ]','gi'), 'a')
  // text = text.replace(new RegExp('[eéèëê]','gi'), 'e')
  // text = text.replace(new RegExp('[iíìïî]','gi'), 'i')
  // text = text.replace(new RegExp('[oóòõöô]','gi'), 'o')
  // text = text.replace(new RegExp('[uúùüû]','gi'), 'u')
  // text = text.replace(new RegExp('[ç]','gi'), 'c')
  return text
}

// O token sempre expira antes do refresh

// Essa função ao ser chamada:
// devolve true se o token estiver expirado
export function tokenExpirou() {
  const usuario = JSON.parse(localStorage.getItem('userData'))
  if (!usuario) {
    return
  }
  const expiracaoToken = new Date(usuario.dataExpiracaoToken)
  const momentoAtual = new Date(Date.now())

  return momentoAtual >= expiracaoToken
}

// Essa função ao ser chamada:
// devolve true se o refresh estiver expirado
export function refreshExpirou() {
  const usuario = JSON.parse(localStorage.getItem('userData'))
  if (!usuario) {
    return
  }
  const expiracaoRefresh = new Date(usuario.dataExpiracaoRefreshToken)
  const momentoAtual = new Date(Date.now())

  return momentoAtual >= expiracaoRefresh
}

export function deslogarUsuario() {
  localStorage.removeItem('userData')
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
  localStorage.setItem('redirecionado', true)
  localStorage.setItem('Rede', true)

  const baseURL = window.location.origin
  location.assign(baseURL + '/login')
  // location.reload()
}

export function renovaToken() {
    // localStorage.removeItem('userData')
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  
  const data = getUserData()
      const toke = useJwt.refreshToken({
        tokenDoUsuario: data.refreshToken,
      }).then((r) => {
          useJwt.setToken(r.data.dados.tokenGerado.token)
          useJwt.setRefreshToken(r.data.dados.tokenGerado.refreshToken)
          useJwt.onAccessTokenFetched(r.data.dados.tokenGerado.token)
          localStorage.removeItem('userData')
          localStorage.setItem(
            'userData',
            JSON.stringify(r.data.dados.tokenGerado)
        )
        location.reload()
      })
 
}

