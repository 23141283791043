import { baseUrl } from '@/constants'
export default {
  // Endpoints
  loginEndpoint: `${baseUrl}/api/autenticacao/autenticar`,
  resetarSenhaEndpoint: `${baseUrl}/api/autenticacao/lembrar-senha`,
  alterarSenhaEndpoint: `${baseUrl}/api/usuario/alterar-senha`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${baseUrl}/api/autenticacao/refresh-token`,
  logoutEndpoint: '/jwt/logout',
  cadastroClientePJEndpoint: '',
  cadastroClientePFEndpoint: '',
  cadastroUsuarioEndpoint: `${baseUrl}/api/usuario/incluir-usuario`,
  listaGerenteEndpoint: `${baseUrl}/api/usuario/listar-gerente`,
  listaGestorEndpoint: `${baseUrl}/api/usuario/listar-gestor`,
  listaClienteEndpoint: `${baseUrl}/api/usuario/listar-cliente`,
  consultarClienteEndpoint: `${baseUrl}/api/usuario/consultar-cliente-completo`,
  consultarUsuarioEndpoint: `${baseUrl}/api/usuario/consultar-usuario-parametro?`,
  alterarUsuarioEndpoint: `${baseUrl}/api/usuario/alterar-usuario`,
  cadastroCargoEndpoint: `${baseUrl}/api/usuario/incluir-funcao`,
  listarCargoEndpoint: `${baseUrl}/api/usuario/listar-funcao`,
  alterarCargoEndpoint: `${baseUrl}/api/usuario/alterar-funcao`,
  enviarAjudaEndpoint: `${baseUrl}/api/dominios/enviar-ajuda`,
  importarUsuarioEndpoint: `${baseUrl}/api/dominios/importar-usuario`,
  salvarTesteDeConcentracaoEndpoint: `${baseUrl}/api/game/resultado-concentracao`,
  buscarTesteDeConcentracaoEndpoint: `${baseUrl}/api/game/consultar-matriz`,
  salvarConfigConcentracaoEndpoint: `${baseUrl}/api/game/incluir-concentracao`,
  salvarConfigMemoriaEndpoint: `${baseUrl}/api/game/incluir-memoria`,
  listarFuncionariosEndpoint: `${baseUrl}/api/usuario/listar-usuario`,
  consultarResultadosTestesEndpoint: `${baseUrl}/api/game/listar-resultados-teste`,
  consultarLocalEndpoint: `${baseUrl}/api/usuario/consulta-base/`,
  alterarLocalEndpoint: `${baseUrl}/api/usuario/alterar-base/`,
  inserirLocalEndpoint: `${baseUrl}/api/usuario/incluir-base/`,
  listarLocalEndpoint: `${baseUrl}/api/usuario/listar-base`,
  listarLocalPorClienteEndpoint: `${baseUrl}/api/usuario/listar-base-cliente`,
  desativarLocalTrabalhoPorId: `${baseUrl}/api/usuario/desativar-base/`,
  consultaLocalPorClienteEndpoint: `${baseUrl}/api/usuario/listar-base/`,
  deletaUsuarioPorId: `${baseUrl}/api/usuario/deletar-usuario/`,
  deletaClientePorId: `${baseUrl}/api/usuario/deletar-cliente/`,
  ativaClientePorId: `${baseUrl}/api/usuario/ativar-cliente/`,
  ativaUsuarioPorId: `${baseUrl}/api/usuario/ativar-usuario/`,
  consultarJogoMemoria: `${baseUrl}/api/game/consultar-configuracao-memoria/`,
  consultarJogoAtencao: `${baseUrl}/api/game/consultar-configuracao-concentracao/`,
  validaCamposUsuario: `${baseUrl}/api/usuario/valida-usuario/`,
  validaCamposCliente: `${baseUrl}/api/usuario/valida-cliente/`,
  consultaDetalheUsuario: `${baseUrl}/api/usuario/consultar-detalhe-usuario/`,
  listarDetalheMediaMemoria: `${baseUrl}/api/usuario/listar-detalhe-media-memoria/`,
  listarDetalheHistoricoConcentracao: `${baseUrl}/api/usuario/listar-historico-concentracao/`,
  listarDetalheHistoricoMemoria: `${baseUrl}/api/usuario/listar-historico-memoria/`,
  listarDetalheMediaConcentracao: `${baseUrl}/api/usuario/listar-detalhe-media-concentracao/`,
  salvarTermosJogosEndpoint: `${baseUrl}/api/autenticacao/aceitar-termos`,
  verificaTermosJogosEndpoint: `${baseUrl}/api/autenticacao/primeiro-login`,
  quantidadeJogosEndpoint: `${baseUrl}/api/game/quantidade-jogo`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
