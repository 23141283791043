import { getUserData } from '@/auth/utils'
export default {
  state: {
    idDoPerfil: Number(getUserData().idDoPerfil),
    nomeDoUsuario: Number(getUserData().nomeDoUsuario),
    idDoCliente: Number(getUserData().idDoCliente),
    idDoUsuario: Number(getUserData().idDoUsuario),
    menu: [],
    referenciaConcentracao: [],
  },
  getters: {
    
    recebeReferencia(ref) { 
      state.referenciaConcentracao = ref
      return state.referenciaConcentracao
    },

    verificaMenu(state) {
      // Zera o menu
      state.menu = []
      // console.log('state: ', state.idDoPerfil)

      // Funções que irão construir a array menu com base no idDoPerfil recebido em Login

      // // Dados cadastrais
      // if (Number(state.idDoPerfil) === 2 || Number(state.idDoPerfil) === 3) {
      //   state.menu.push({
      //     title: 'Dados Cadastrais',
      //     // route: '',
      //     icon: 'SettingsIcon',
      //   })
      // }

      // Clientes
      if (Number(state.idDoPerfil) === 1) {
        state.menu.push({
          title: 'Clientes',
          icon: 'HomeIcon',
          route: 'client-page',
          // children: [
          //   {
          //     title: 'Listar',
          //     route: 'client-page',
          //   },
          //   {
          //     title: 'Cadastrar',
          //     route: 'client-register',
          //   },
          // ],
        })
      }

      // Usuários
      if (
        Number(state.idDoPerfil) === 1 ||
        Number(state.idDoPerfil) === 2 ||
        Number(state.idDoPerfil) === 3 ||
        Number(state.idDoPerfil) === 4
      ) {
        state.menu.push({
          title: 'Usuários',
          icon: 'UsersIcon',
          route: 'user-page',
          // children: [],
        })
      }

      // Subnível de usuários:
      // if (
      //   Number(state.idDoPerfil) === 1 ||
      //   Number(state.idDoPerfil) === 2 ||
      //   Number(state.idDoPerfil) === 3 ||
      //   Number(state.idDoPerfil) === 4
      // ) {
      //   state.menu
      //     .find((x) => x.title === 'Usuários')
      //     .children.push(
      //       {
      //         title: 'Listar',
      //         route: 'user-page',
      //       },
      //       {
      //         title: 'Cadastrar',
      //         route: 'user-register',
      //       }
      //     )
      // }

      // if (
      //   Number(state.idDoPerfil) === 1 ||
      //   Number(state.idDoPerfil) === 2 ||
      //   Number(state.idDoPerfil) === 3
      // ) {
      //   state.menu
      //     .find((x) => x.title === 'Usuários')
      //     .children.push({
      //       title: 'Importar',
      //       route: 'user-import',
      //     })
      // }

      // Local de Trabalho
      if (Number(state.idDoPerfil) === 2 || Number(state.idDoPerfil === 1)) {
        state.menu.push({
          title: 'Local de Trabalho',
          route: 'consultar-trabalho',
          icon: 'MapIcon',
          // children: [
          //   {
          //     title: 'Listar',
          //     route: 'consultar-trabalho',
          //   },
          //   {
          //     title: 'Cadastrar',
          //     route: 'cadastrar-trabalho',
          //   },
          // ],
        })
      }

      // Cargos
      if (
        Number(state.idDoPerfil) === 2 ||
        Number(state.idDoPerfil) === 3 ||
        Number(state.idDoPerfil === 1)
      ) {
        state.menu.push({
          title: 'Cargos',
          route: 'role-view',
          icon: 'FolderIcon',
          // children: [
          //   {
          //     title: 'Listar',
          //     route: 'role-view',
          //   },
          //   {
          //     title: 'Cadastrar',
          //     route: 'role-add',
          //   },
          // ],
        })
      }

      // Consultar Testes
      if (
        Number(state.idDoPerfil) === 1 || //Master
        Number(state.idDoPerfil) === 2 || //Administrador
        Number(state.idDoPerfil) === 3 || //Gerente
        Number(state.idDoPerfil) === 4  //Gestor
      ) {
        state.menu.push({
          title: 'Consultar',
          route: 'consultar-testes',
          icon: 'SearchIcon',
        })
      }

      // Teste
      if (Number(state.idDoPerfil) === 2 ||
          Number(state.idDoPerfil) === 3 ||
          Number(state.idDoPerfil) === 4 ||
          Number(state.idDoPerfil) === 5) {
        state.menu.push({
          title: 'Jogos',
          icon: 'GridIcon',
          route: 'test',
        })
      }

      // Subnível de teste: Menu para o perfil usuário
      // if (Number(state.idDoPerfil) === 5) {
      //   state.menu
      //     .find((x) => x.title === 'Teste')
      //     .children.push(
      //       {
      //         // Tela de seleção de testes
      //         title: 'Seleção',
      //         route: 'test',
      //       },
      //       // {
      //       //   // Teste de concentração (não aparece, habilitar apenas para testes)
      //       //   title: 'TConcentração',
      //       //   route: 'concentracao',
      //       // },
      //       {
      //         // Instruções do teste de concentração
      //         title: 'Concentração',
      //         route: 'preconcentracao',
      //       },
      //       {
      //         // Memória
      //         title: 'Memória',
      //         route: 'prememoria',
      //       }
      //     )
      // }

      // Ajuda
      if (Number(state.idDoPerfil) === 2 ||
          Number(state.idDoPerfil) === 3 ||
          Number(state.idDoPerfil) === 4 ||
          Number(state.idDoPerfil) === 5) {
        state.menu.push({
          title: 'Ajuda',
          route: 'user-help',
          icon: 'HelpCircleIcon',
        })
      }

      // Relatórios
      // if (Number(state.idDoPerfil) === 2 || Number(state.idDoPerfil) === 3) {
      //   state.menu.push({
      //     title: 'Relatórios',
      //     // route: '',
      //     icon: 'TrelloIcon',
      //   })
      // }

      // Configurações
      if (Number(state.idDoPerfil) === 1) {
        state.menu.push({
          title: 'Configurações',
          icon: 'SettingsIcon',
          children: [],
        })
      }

      // Subnível de configurações: Menu para o perfil master
      if (Number(state.idDoPerfil) === 1) {
        state.menu
          .find((x) => x.title === 'Configurações')
          .children.push(
            {
              // Tela de configuração do teste de concentração
              title: 'Jogo de Atenção',
              route: 'configura-concentracao',
            },
            // {
            //   // Tela de configuração do teste de memória
            //   title: 'Jogo de Memória',
            //   route: 'configura-memoria',
            // }
          )
      }

      return state.menu
    },
  },
  mutations: {
    setPerfil(state, idDoPerfil) {
      state.idDoPerfil = idDoPerfil
    },
    listaReferencia(state, referencia) { 
      //console.log('LISTANDO REFERENCIA')
      //console.log(referencia)

      state.referenciaConcentracao = referencia 
    }
  },
}
