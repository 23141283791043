export default [
  {
    path: '/usuarios',
    name: 'user-page',
    component: () => import('@/views/User.vue'),
    meta: {
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Usuários',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadastro-de-usuario',
    name: 'user-register',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Cadastro de usuário',
      breadcrumb: [
        {
          text: 'Usuários',
          to: { name: 'user-page' },
        },
        {
          text: 'Cadastro de usuário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultar-usuario/:idDoCliente/:idDoUsuario',
    name: 'user-search',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Detalhes do Usuário',
      breadcrumb: [
        {
          text: 'Usuários',
          to: { name: 'user-page' },
        },
        {
          text: 'Detalhes de usuário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/editar-usuario/:idDoCliente/:idDoUsuario',
    name: 'user-edit',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Editar usuário',
      breadcrumb: [
        {
          text: 'Usuários',
          to: { name: 'user-page' },
        },
        {
          text: 'Editar usuário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultar-usuario/:idDoCliente/:idDoUsuario',
    name: 'user-profile',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Detalhes do Usuário',
      breadcrumb: [
        {
          text: 'Usuários',
          to: { name: 'user-page' },
        },
        {
          text: 'Detalhes de usuário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/importar-funcionarios',
    name: 'user-import',
    component: () => import('@/views/forms/Registration/Import.vue'),
    meta: {
      pageTitle: 'Importação de usuários',
      breadcrumb: [
        {
          text: 'Usuários',
          to: { name: 'user-page' },
        },
        {
          text: 'Importação de usuários',
          active: true,
        },
      ],
    },
  },
]
