export default [
  {
    path: '/teste/selecao',
    name: 'test',
    component: () => import('@/views/game/Selecao.vue'),
    meta: {
      pageTitle: 'Jogos',
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'home' },
        },
        {
          text: 'Jogos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teste/concentracao/game',
    name: 'concentracao',
    component: () => import('@/views/game/concentracao/TesteConcentracao.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Atenção',
      breadcrumb: [
        {
          text: 'Jogo',
          to: { name: 'test' },
        },
        {
          text: 'Atenção',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teste/concentracao/instrucoes',
    name: 'preconcentracao',
    component: () =>
      import('@/views/game/concentracao/PreTesteConcentracao.vue'),
    meta: {
      pageTitle: 'Instruções',
      breadcrumb: [
        {
          text: 'Jogo',
          to: { name: 'test' },
        },
        {
          text: 'Instruções do jogo de Atenção',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teste/memoria',
    name: 'memoria',
    component: () => import('@/views/game/memoria/Memoria.vue'),
    meta: {
      layout: 'full',
      // pageTitle: 'Jogo',
      // breadcrumb: [
      //   {
      //     text: 'Jogo',
      //     to: { name: 'test' },
      //   },
      //   {
      //     text: 'Memória',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/teste/memoria/instrucoes',
    name: 'prememoria',
    component: () => import('@/views/game/memoria/PreTesteMemoria.vue'),
    meta: {
      pageTitle: 'Instruções',
      breadcrumb: [
        {
          text: 'Jogo',
          to: { name: 'test' },
        },
        {
          text: 'Instruções do jogo de Memória',
          active: true,
        },
      ],
    },
  },
]
