export default [
  {
    path: '/configuracoes/concentracao/',
    name: 'configura-concentracao',
    component: () =>
      import('@/views/game/concentracao/ConfiguraConcentracao.vue'),
    meta: {
      pageTitle: 'Configuração',
      breadcrumb: [
        {
          text: 'Configurações',
          to: { name: 'test' },
          active: true,
        },
        {
          text: 'Configurações do Jogo de Atenção',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configuracoes/memoria/',
    name: 'configura-memoria',
    component: () => import('@/views/game/memoria/ConfiguraMemoria.vue'),
    meta: {
      pageTitle: 'Configuração',
      breadcrumb: [
        {
          text: 'Configurações',
          to: { name: 'test' },
          active: true,
        },
        {
          text: 'Configurações do Jogo de Memória',
          active: true,
        },
      ],
    },
  },
]
