export default [
  {
    path: '/ajuda',
    name: 'user-help',
    component: () => import('@/views/forms/Help.vue'),
    meta: {
      pageTitle: 'Ajuda',
      breadcrumb: [
        {
          text: 'Ajuda',
          active: true,
        },
      ],
    },
  },
]
