/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable */
import axios from 'axios'
// import Vue from 'vue'
import jwtDefaultConfig from './jwtDefaultConfig'
import { baseUrl } from '@/constants'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  tokenExpirou,
  refreshExpirou,
  deslogarUsuario,
} from '@/auth/utils'


function redirecionaParaLoginSeTokenExpirou() {
  // console.log('Chegou no início')
  if (getUserData() !== null) {
    // console.log('Chegou no meio')
    // Verifica se o refresh expirou e se o token expirou
    // Desloga usuário caso sim
    // console.log(
    //   `Verifica token: ${tokenExpirou()} e refresh ${refreshExpirou()}`)
    if (tokenExpirou()) {
      // console.log('CHEGOU AQUI')
        renovaToken()
    }
     
    if (tokenExpirou() && refreshExpirou()) {
      // console.log('Chegou no executar')
      deslogarUsuario()
    }

    // console.log('Continuar')
  }
}




export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        redirecionaParaLoginSeTokenExpirou()
        // console.log('Interceptor config: ', config)

        return config
      },
      (error) => Promise.reject(error)
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => {
        // console.log('Interceptor response: ', response)

        return response
      },
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            const data = getUserData()
            this.refreshToken({
              tokenDoUsuario: data.refreshToken,
            }).then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.dados.tokenGerado.result.token)
              this.setRefreshToken(r.data.dados.tokenGerado.result.refreshToken)
              this.onAccessTokenFetched(r.data.dados.tokenGerado.result.token)
              localStorage.removeItem('userData')
              localStorage.setItem(
                'userData',
                JSON.stringify(r.data.dados.tokenGerado.result)
              )
            })
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  async refreshToken(...args) {
    return await axios.post(this.jwtConfig.refreshEndpoint, ...args)
  }

  resetarSenha(...args) {
    return this.axiosIns.post(this.jwtConfig.resetarSenhaEndpoint, ...args)
  }

  alterarSenha(...args) {
    return this.axiosIns.post(this.jwtConfig.alterarSenhaEndpoint, ...args)
  }

  enviarAjuda(...args) {
    return this.axiosIns.post(this.jwtConfig.enviarAjudaEndpoint, ...args)
  }

  // Clientes
  getClientes() {
    return this.axiosIns.get(`${baseUrl}/api/usuario/listar-cliente`)
  }

  // Formulários:
  cadastroClientePF(...args) {
    return this.axiosIns.post(
      `${baseUrl}/api/usuario/incluir-cliente-fisico`,
      ...args
    )
  }

  importarUsuario(idDoCliente, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.importarUsuarioEndpoint}?idCliente=${idDoCliente}`,
      ...args
    )
  }

  listarFuncionarios(...args) {
    // Lista de usuários
    return this.axiosIns.get(`${this.jwtConfig.listarFuncionariosEndpoint}`)
  }

  cadastroClientePJ(...args) {
    return this.axiosIns.post(
      `${baseUrl}/api/usuario/incluir-cliente-juridico`,
      ...args
    )
  }

  cadastroUsuario(...args) {
    return this.axiosIns.post(this.jwtConfig.cadastroUsuarioEndpoint, ...args)
  }

  cadastroCargo(...args) {
    return this.axiosIns.post(this.jwtConfig.cadastroCargoEndpoint, ...args)
  }

  cadastroMemoria(...args) {

    return this.axiosIns.post(`${baseUrl}/api/game/resultado-memoria`, ...args)
  }

  async consultaCargo(id) {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/consultar-funcao/${id}`
    )
    return response
  }

  async consultaMemoria() {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/game/consultar-memoria`
    )
    return response
  }

  async listRoles() {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/listar-funcao`
    )
    return response
  }

    async listRolesCliente(id) {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/listar-funcao-cliente/${id}`
    )
    return response
  }

    async listRolesAll() {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/listar-funcao-geral`
    )
    return response
  }

  async alterarCargo(body, id) {
    const url = this.jwtConfig.alterarCargoEndpoint
    const response = await this.axiosIns.put(`${url}/${id}`, body)
    return response
  }

  async alterarUsuario(body, id) {
    // Descomentar linhas abaixo a fim de visualizar no log
    const url = this.jwtConfig.alterarUsuarioEndpoint
    const response = await this.axiosIns.put(`${url}/${id}`, body)
    return response
  }

  async consultarUsuario(idDoCliente, idDoUsuario) {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/consultar-usuario-parametro/${idDoCliente}/${idDoUsuario}`
    )
    return response
  }

  //Retorna todos os Gerentes
  async getGerente() {
    const response = await this.axiosIns.get(
      this.jwtConfig.listaGerenteEndpoint
    )
    return response
  }

  //Retorna Gerentes pelo id do Cliente
  async getGerentePorIdCliente(idCliente) {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/listar-gerente/${idCliente}`
    )
    return response
  }

  async getCep(cep) {
    return this.axiosIns.get(`${baseUrl}/api/dominios/cep/${cep}`)
  }

  async getGestorPorIdCliente(id) {
    const response = await this.axiosIns.get(
      `${baseUrl}/api/usuario/listar-gestor/${id}`
    )
    return response
  }

  async getGestor() {
    const response = await this.axiosIns.get(this.jwtConfig.listaGestorEndpoint)
    return response
  }

  // serviço que retornar um unico cliente
  async getClienteById(id) {
    return this.axiosIns.get(`${this.jwtConfig.consultarClienteEndpoint}/${id}`)
  }

  // serviço que retorna lista de Clientes
  async getListaDeClientes() {
    const response = await this.axiosIns.get(
      this.jwtConfig.listaClienteEndpoint
    )
    return response
  }

  async inactivate(id) {
    return this.axiosIns.post(`${baseUrl}/api/usuario/desativar-cliente/${id}`)
  }

  async inactivateUser(id) {
    return this.axiosIns.post(`${baseUrl}/api/usuario/desativar-usuario/${id}`)
  }

  async removeUserById(id) {
    return await this.axiosIns.delete(this.jwtConfig.deletaUsuarioPorId + id)
  }

  async removeClientById(id) {
    return await this.axiosIns.delete(this.jwtConfig.deletaClientePorId + id)
  }

  async ativarClientById(id) {
    return await this.axiosIns.post(this.jwtConfig.ativaClientePorId + id)
  }

  async ativarUserById(id) {
    return await this.axiosIns.post(this.jwtConfig.ativaUsuarioPorId + id)
  }

  async inactivateRole(id) {
    return this.axiosIns.post(`${baseUrl}/api/usuario/desativar-funcao/${id}`)
  }

  async updateClientPF(id, ...args) {
    return this.axiosIns.put(
      `${baseUrl}/api/usuario/alterar-cliente-fisico/${id}`,
      ...args
    )
  }

  async updateClientPJ(id, ...args) {
    return this.axiosIns.put(
      `${baseUrl}/api/usuario/alterar-cliente-juridico/${id}`,
      ...args
    )
  }

  async getClienteById(id) {
    const response = this.axiosIns.get(
      `${baseUrl}/api/usuario/consultar-cliente-completo/${id}`
    )
    return response
  }

  salvarConfigConcentracao(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.salvarConfigConcentracaoEndpoint}`,
      ...args
    )
  }

  salvarConfigMemoria(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.salvarConfigMemoriaEndpoint}`,
      ...args
    )
  }

  salvarTesteDeConcentracao(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.salvarTesteDeConcentracaoEndpoint}`,
      ...args
    )
  }

  validarCamposUsuario(...args) { 
    return this.axiosIns.post(
      `${this.jwtConfig.validaCamposUsuario}`,
      ...args
    )
  }

  salvarTermosJogos(...args){
    return this.axiosIns.post(
      `${this.jwtConfig.salvarTermosJogosEndpoint}`,
      ...args
    )
  }

  validarCamposCliente(...args) { 
    return this.axiosIns.post(
      `${this.jwtConfig.validaCamposCliente}`,
      ...args
    )
  }

  verificaTermos(){
    return this.axiosIns.get(this.jwtConfig.verificaTermosJogosEndpoint)
  }

  quantidadeJogos(){
    return this.axiosIns.get(this.jwtConfig.quantidadeJogosEndpoint)
  }

  buscarTesteDeConcentracao() {
    return this.axiosIns.get(this.jwtConfig.buscarTesteDeConcentracaoEndpoint)
  }

  consultaJogoMemoria() { 
    return this.axiosIns.get(this.jwtConfig.consultarJogoMemoria)
  }

  consultaJogoAtencao() { 
    return this.axiosIns.get(this.jwtConfig.consultarJogoAtencao)
  }

  consultarResultadosTestes(...args) {
    return this.axiosIns.post(
      this.jwtConfig.consultarResultadosTestesEndpoint,
      ...args
    )
  }

  async inserirLocal(...args) {
    return await this.axiosIns.post(
      this.jwtConfig.inserirLocalEndpoint,
      ...args
    )
  }

  async consultarLocal(localId) {
    return await this.axiosIns.get(
      this.jwtConfig.consultarLocalEndpoint + localId
    )
  }

  async alterarLocal(localId, ...args) {
    return await this.axiosIns.put(
      this.jwtConfig.alterarLocalEndpoint + localId,
      ...args
    )
  }

  async listarLocal() {
    const response = await this.axiosIns.get(this.jwtConfig.listarLocalEndpoint)
    return response
  }

  async listarLocalPorCliente(...args) {
    return await this.axiosIns.get(
      this.jwtConfig.listarLocalPorClienteEndpoint,
      ...args
    )
  }

    async consultaLocalPorCliente(id, ...args) {
    return await this.axiosIns.get(
      this.jwtConfig.consultaLocalPorClienteEndpoint + id,
      ...args
    )
  }

  async consultarResultadoDetalhado(id, tipoJogo, ...args) {
    return await this.axiosIns.get(
      this.jwtConfig.consultaDetalheUsuario + id + '/' + tipoJogo,
      ...args
    )
  }
  async listarDetalheMediaMemoria(id, ...args) {
    return await this.axiosIns.get(
      this.jwtConfig.listarDetalheMediaMemoria + id,
      ...args
    )
  }
  async listarDetalheHistoricoConcentracao(id, ...args) {
    return await this.axiosIns.get(
      this.jwtConfig.listarDetalheHistoricoConcentracao + id,
      ...args
    )
  }
  async listarDetalheHistoricoMemoria(id, ...args) {
    return await this.axiosIns.get(
      this.jwtConfig.listarDetalheHistoricoMemoria + id,
      ...args
    )
  }
  async listarDetalheMediaConcentracao(id, ...args) {
    return await this.axiosIns.get(
      this.jwtConfig.listarDetalheMediaConcentracao + id,
      ...args
    )
  }
  

  async DesativarBaseById(id, ...args) {
    return await this.axiosIns.put(
      this.jwtConfig.desativarLocalTrabalhoPorId + id,
      ...args
    )
  }
}
