export default [
  {
    path: '/clientes',
    name: 'client-page',
    component: () => import('@/views/Clients.vue'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Clientes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadastro-de-cliente',
    name: 'client-register',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Cadastro de cliente',
      breadcrumb: [
        {
          text: 'Clientes',
           to: { name: 'client-page' },
        },
        {
          text: 'Cadastro de cliente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/editar-cliente/:id',
    name: 'client-edit',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Editar cliente',
      breadcrumb: [
        {
          text: 'Clientes',
          to: { name: 'client-page' },
        },
        {
          text: 'Editar cliente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultar-cliente/:id',
    name: 'client-detail',
    component: () => import('@/views/forms/Registration/FormWizard.vue'),
    meta: {
      pageTitle: 'Detalhes do cliente',
      breadcrumb: [
        {
          text: 'Clientes',
          to: { name: 'client-page' },
        },
        {
          text: 'Detalhes do cliente',
          active: true,
        },
      ],
    },
  },
]
